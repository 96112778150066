import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AppLangs } from '../i18n';
import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { PublicRoutesRecord } from '../routes/router/Public.router';
import { getCurrentLang } from '../utils/getCurrentLang';

const usePageTracking = () => {
  const location = useLocation();
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId);

  useEffect(() => {
    if (userId?.length || process.env.NODE_ENV !== 'production') {
      return;
    }

    const currentLang = getCurrentLang();
    const validPaths = Object.values(PublicRoutesRecord).map(
      (r) => `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${r}`,
    );

    if (validPaths.includes(location.pathname)) {
      // @ts-ignore Google Analytics
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, userId]);
};

export default usePageTracking;
