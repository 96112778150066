import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Tooltip, TooltipProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraToast } from '../utils/AtiraToast';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';

type CopyButtonProps = {
  copy: string;
  text?: string;
  icon?: IconProp;
} & Partial<TooltipProps>;

export const CopyButton: React.FC<CopyButtonProps> = ({
  copy,
  text,
  icon,
  ...props
}) => {
  const { t } = useTranslation();

  const onCopy = () => {
    navigator.clipboard.writeText(copy);
    AtiraToast.success(t('common.copy.success'));
  };

  return (
    <Tooltip title={text || t('common.copy')} {...props}>
      <Button onClick={onCopy} backgroundColor="transparent">
        <AtiraIcon color="darkSub" size="2x" icon={icon || faCopy} />
      </Button>
    </Tooltip>
  );
};
